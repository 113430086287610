import "../App.css";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Tooltip,
  OverlayTrigger,
  Modal,
  Spinner,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import { Currencies } from "./Currencies";
import { Codes } from "./CountryCodes";
import { addDoc, apiHost, setDoc } from "../Helper";
import Tick from "../asstes/tick.svg";
import { Cities as cityData } from "./newCountry";
import SignalRService from "../SignalR";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Iicons from "../asstes/infobox.png"
export let ordernumber = -1;

function CreateOrder(props) {
  const [showform, setshowform] = useState(false);
  const navigate = useNavigate();
  const activateBtn = useRef();
  const [Countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHold, setIsLoadingHold] = useState(false);
  const [giveStates, setgiveStates] = useState([]);
  const [takeStates, settakeStates] = useState([]);
  const [giveCities, setgiveCities] = useState([]);
  const [takeCities, settakeCities] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [takecurrency, settakecurrency] = useState(null);
  const [iseditORder, setiseditORder] = useState(false);
  const [givecurrency, setgivecurrency] = useState(null);
  const [takeCountryId, settakeCountryId] = useState(null);
  const [takestateId, settakestateId] = useState(null);
  const [giveCountryId, setgiveCountryId] = useState(null);
  const [stateArray, setStateArray] = useState([]);
  const [givestateId, setgivestateId] = useState(null);
  const [userdata, setuserdata] = useState("");
  const [modalID, setModalID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [watchlistDocList, setwatchlistDocList] = useState({});
  const [datedisable, setdatedisable] = useState(false);
  const [useselectedstate, setuseState] = useState("");
  const [expirytime, setexpirytime] = useState("");
  const [useselectedcity, setuseselectedcity] = useState(null);
  const [selectedcountry, setselectedcountry] = useState("");
  const [usegiveselectedstate, setgiveuseState] = useState("");
  const [usegiveselectedcity, setgiveuseselectedcity] = useState(null);
  const [giveselectedcountry, setgiveselectedcountry] = useState("");
  const [AddAmount, setAddAmount] = useState("");
  const [output, setOutput] = useState("");
  const [outputGive, setOutputGive] = useState("");
  const [watchlistDocid, setWatchlistDocid] = useState("");
  const [suggestion, setsuggestion] = useState("");
  const [giveSuggestion, setGiveSuggestion] = useState("");
  const [takeSuggestion, setTakeSuggestion] = useState("");
  const [giveamount, setgiveamount] = useState("");
  const [takeamount, settakeamount] = useState("");
  const [Cash, setCash] = useState(false);
  const [Bank, setBank] = useState(false);
  const [takeCash, settakeCash] = useState(false);
  const [takeBank, settakeBank] = useState(false);
  // const [goodcheck, setgoodcheck] = useState(false);
  const [amountSurety, setAmountSurety] = useState(false);
  const [amountSuretyGive, setAmountSuretyGive] = useState(false);
  const [docid, setDocid] = useState("");
  const [orderslist, setorderslist] = useState("");
  const [formatoutput, setformatoutput] = useState("");
  const [formatoutputGive, setformatoutputGive] = useState("");
  const [UntilGoodDate, SetUntilGoodDate] = useState("");
  const [givecurrencyerr, setgivecurrencyerror] = useState("");
  const [giveamounterr, setgiveamounterr] = useState("");
  const [takecurrencyerr, settakecurrencyerr] = useState("");
  const [takeamounterr, settakeamounterr] = useState("");
  const [amountSuretyerr, setAmountSuretyerr] = useState("");
  const [amountSuretyerrGive, setAmountSuretyerrGive] = useState("");
  const [Docidtype, setDocidtype] = useState("");
  const [serial_no, setserial_no] = useState("");
  const [maxDate, setmaxDate] = useState(0);
  const [isQuickOrder, setisQuickOrder] = useState(false);
  const [time, setTime] = useState("");
  const [ddate, setDdate] = useState("");

  //////////////////////////FX//////////////
  const [type, settype] = useState("password");
  const [type1, settype1] = useState("password");
  const [email, setEmail] = useState("");
  const [checkcode, setcheckcode] = useState(" ");
  const [Verifycode, setverifycode] = useState("");
  const [verifiedcode, setverifiedcode] = useState(" ");
  const [emailverify, setemailverify] = useState(false);
  const [border, setborder] = useState(false);
  const [checkemail, setcheckEmail] = useState(" ");
  const [passcheck, setpasscheck] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [passworderr, setPassworderror] = useState("");
  const [password, setPassword] = useState("");
  const [emailerr, setEmailerror] = useState("");
  const [usernameerr, setusernameerr] = useState("");
  const [UserName, setUsername] = useState("");
  const [mcOrderId, setMcOrderId] = useState();
  const [showGiveSuggestion, setShowGiveSuggestion] = useState(false);
  const [showTakeSuggestion, setShowTakeSuggestion] = useState(false);
  const [takelengtherr, settakelengtherr] = useState(null);
  const [giveLengtherr, setgiveLengtherr] = useState(null);
  const [rates, setRates] = useState(null);
  const [countryerr, setcountryerr] = useState("");
  const [selectedlogincountry, setSelectedLoginCountry] = useState("");

  const [error, setError] = useState({
    giveCountryErr: "",
    giveStateErr: "",
    giveCityErr: "",
    takeCountryErr: "",
    takeStateErr: "",
    takeCityErr: "",
  });

  document.addEventListener("DOMContentLoaded", function () {
    if (props.is1FX) {
      document.getElementById("root").scrollTo(0, 0);
    }
  });
  const changetype = async () => {
    settype("input");
  };

  const changetypepass = async () => {
    settype("password");
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click Verify and check your email to verify account
    </Tooltip>
  );

  const changetype1 = async () => {
    settype1("input");
  };
  const changetypepass1 = async () => {
    settype1("password");
  };

  useEffect(() => {
    if (props.is1FX) {
      if (checkcode != Verifycode && checkcode.length > 3) {
        document.getElementById("codeerr").style.display = "block";
      } else {
        document.getElementById("codeerr").style.display = "none";
      }
    }
  }, [checkcode]);

  useEffect(() => {
    if (props.is1FX) {
      if (checkcode == Verifycode && checkemail === email) {
        setverifiedcode(checkcode);
        setborder(true);
        setemailverify(true);
        setshowform(false);
        document.getElementById("tick").style.display = "block";
        document.getElementById("email").disabled = true;
        document.getElementById("codebutton").className = "btnverifygreyed";
        document.getElementById("codebutton").innerHTML = "Verified/Change";
      } else {
        setemailverify(false);
        document.getElementById("codebutton").style.display = "block";
        document.getElementById("tick").style.display = "none";

        setborder(false);
      }
    }
  }, [checkcode, Verifycode, email, verifiedcode]);

  useEffect(() => {
    if (props.is1FX) {
      setisQuickOrder(true);
      document.getElementById("rate-date-time").style.display = "none";
    }
  }, [props.is1FX]);
  /////////////////////////////////////////////////////////////////////

  const getOrder = async () => {
    if (docid !== "" && docid !== 0) {
      let url = `${apiHost}/api/Orders/GetOrderById?OrderId=${docid}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          ////console.log(response.data)
          const orderdata = { ...response.data };
          setorderslist(orderdata);
          setserial_no(response.data.length);
        })
        .catch((error) => {
          ////console.log(error);
        });
    }
  };

  function errorCheck() {
    let errorBorder = false;
    let errCount = 0;
    let errObj = { ...error };
    console.log();
    if (givecurrency === null || givecurrency == "") {
      document.getElementById("root").scrollTo(0, 0);
      setgivecurrencyerror("Missing Field");
      errorBorder = false;
      errCount += 1;
    }
    if (giveamount == "" || giveamount == null) {
      document.getElementById("root").scrollTo(0, 0);
      setgiveamounterr("Missing Field");
      errorBorder = false;
      errCount += 1;
    }
    if (selectedcountry === null || selectedcountry == "") {
      document.getElementById("root").scrollTo(0, 0);
      errObj = { ...errObj, giveCountryErr: "Missing Field." };
      errorBorder = false;
      errCount += 1;
    }
    if (useselectedstate === null || useselectedstate == "") {
      document.getElementById("root").scrollTo(0, 0);
      errObj = { ...errObj, giveStateErr: "Missing Field." };
      errorBorder = false;
      errCount += 1;
    }
    if (useselectedcity === null || useselectedcity == "") {
      document.getElementById("root").scrollTo(0, 0);
      errObj = { ...errObj, giveCityErr: "Missing Field." };
      errorBorder = false;
      errCount += 1;
    }
    if (takecurrency === null || takecurrency == "") {
      document.getElementById("root").scrollTo(0, 200);
      settakecurrencyerr("Missing Field");
      errorBorder = false;
      errCount += 1;
    }
    if (takeamount == "" || takeamount == null) {
      document.getElementById("root").scrollTo(0, 200);
      settakeamounterr("Missing Field");
      errorBorder = false;
      errCount += 1;
    }
    if (giveselectedcountry === null || giveselectedcountry == "") {
      document.getElementById("root").scrollTo(0, 0);
      errObj = { ...errObj, takeCountryErr: "Missing Field." };
      errorBorder = false;
      errCount += 1;
    }
    if (usegiveselectedstate === null || usegiveselectedstate == "") {
      document.getElementById("root").scrollTo(0, 0);
      errObj = { ...errObj, takeStateErr: "Missing Field." };
      errorBorder = false;
      errCount += 1;
    }
    if (usegiveselectedcity === null || usegiveselectedcity == "") {
      document.getElementById("root").scrollTo(0, 0);
      errObj = { ...errObj, takeCityErr: "Missing Field." };
      errorBorder = false;
      errCount += 1;
    }
    if (takelengtherr != null && amountSurety == false) {
      document.getElementById("root").scrollTo(0, 500);
      setAmountSuretyerr("Please check the box");
      errorBorder = false;
      errCount += 1;
    }
    if (giveLengtherr != null && amountSuretyGive == false) {
      document.getElementById("root").scrollTo(0, 500);
      setAmountSuretyerrGive("Please check the box");
      errorBorder = false;
      errCount += 1;
    }
    setError({ ...errObj });
    if (errCount == 0) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let mcOrderId = urlParams.get("mcOrderId");
    setMcOrderId(mcOrderId);
    if (mcOrderId) {
      let url = `${apiHost}/api/Orders/GetOrderById?OrderId=${mcOrderId}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          
          ////console.log(response.data)
          const orderdata = { ...response.data };
          if (orderdata.takeCurrencyInfo === undefined) {
            settakecurrency(orderdata.giveCurrency);
            setgivecurrency(orderdata.takeCurrency);
          } else {
            settakecurrency(orderdata.giveCurrencyInfo);
            setgivecurrency(orderdata.takeCurrencyInfo);
          }
          setgiveamount(orderdata.takeAmount.toLocaleString("en-US"));
          //setgiveCountryInfo(orderdata.takeCountryId);
          setselectedcountry(orderdata.takeCountry);
          setuseState(orderdata.takeState);
          setuseselectedcity(orderdata.takeCity);
          setBank(orderdata.takeThroughBank);
          setCash(orderdata.takeThroughCash);
          //extra ig
          setgiveCountryId(orderdata.takeCountryId);
          setgivestateId(orderdata.takestateId);

          //GIVE
          settakeamount(orderdata.giveAmount.toLocaleString("en-US"));
          setgiveselectedcountry(orderdata.giveCountry);
          setgiveuseState(orderdata.giveState);
          setgiveuseselectedcity(orderdata.giveCity);
          settakeCash(orderdata.giveSourceCash);
          settakeBank(orderdata.giveSourceBank);
          //setAddAmount(orderdata.additionalInfo);
          //setStartDate(new Date(orderdata.untilGoodDate));
        })
        .catch((error) => {
          ////console.log(error);
        });
    }
    let date = new Date();
    date.setFullYear(date.getFullYear() + 5);
    setmaxDate(date);
    getRates();
    ////console.log("date//////////////// ", date);
  }, [null]);

  const hideModal = () => {
    setShowModal(false);
    navigate(`/OrderCom?ID=${uid}&t=${Date.now()}`);
  };
  useEffect(async () => {
    getOrder();
  }, [docid]);

  useEffect(() => {
    if (orderslist === undefined || orderslist == "") {
    } else {
      if (orderslist.takeCurrencyInfo === undefined) {
        settakecurrency(orderslist.takeCurrency);
        setgivecurrency(orderslist.giveCurrency);
      } else {
        settakecurrency(orderslist.takeCurrencyInfo);
        setgivecurrency(orderslist.giveCurrencyInfo);
      }
      setiseditORder("true");
      settakeamount(orderslist.takeAmount.toLocaleString("en-US"));
      setgiveamount(orderslist.giveAmount.toLocaleString("en-US"));
      setselectedcountry(orderslist.giveCountry);
      // setgoodcheck(orderslist.untilcheck)
      setuseState(orderslist.giveState);
      setuseselectedcity(orderslist.giveCity);
      setgiveselectedcountry(orderslist.takeCountry);
      setgiveuseState(orderslist.takeState);
      setgiveuseselectedcity(orderslist.takeCity);
      settakeCash(orderslist.takeThroughCash);
      settakeBank(orderslist.takeThroughBank);
      setBank(orderslist.giveSourceBank);
      setCash(orderslist.giveSourceCash);
      // setdatedisable(true);
      setAddAmount(orderslist.additionalInfo);
      if (orderslist.status === "expired") {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 30));
        setStartDate(futureDate);
      } else {
        // If the order is not expired, set the start date based on the ordersList.untilGoodDate
        setStartDate(new Date(orderslist.untilGoodDate + "Z"));
      }
      if (orderslist.status === "expired") {
        getdate();
      } else {
        SetUntilGoodDate(orderslist.untilGoodDate);
      }
      
    }
  }, [orderslist]);

  function handleCashSource() {
    setCash(!Cash);
  }
  function handleAmountSurety() {
    setAmountSuretyerr("");
    setAmountSurety(!amountSurety);
  }
  function handleAmountSuretyGive() {
    setAmountSuretyerrGive("");
    setAmountSuretyGive(!amountSuretyGive);
  }
  function handleBankSource() {
    setBank(!Bank);
  }
  function handletakeCashSource() {
    settakeCash(!takeCash);
  }
  function handletakeBankSource() {
    settakeBank(!takeBank);
  }
  // function handleGooduntil() {
  //   setgoodcheck(!goodcheck);
  // }
  useEffect(() => {
    //console.log(amountSuretyGive);
  }, [amountSuretyGive]);
  useEffect(() => {
    var stringWithoutCommas = output.replace(/,/g, "");
    var number = Math.floor(stringWithoutCommas);
    var stringWithout = takeamount;
    var take_num = Math.floor(stringWithout);
    var percentToGet = 15;
    var percent = (percentToGet / 100) * number;
    let max_num = number + percent;
    let min_num = number - percent;
  }, [output, takeamount]);

  const getdate = async () => {
    setStartDate(new Date(Date.now() + 864000000 * 3));
    let x = new Date(Date.now() + 864000000 * 3);
    setexpirytime(
      x
        .toLocaleDateString("en-us", {
          month: "numeric",
          day: "numeric",
          year: "numeric",
        })
        .replaceAll("/", "-")
    );
    SetUntilGoodDate(x.toISOString());
  };
  const styles = {
    err: {
      borderColor: "red",
      borderWidth: "1px",
    },
    show: {
      display: "block",
    },
    hide: {
      display: "none",
    },
  };
  useEffect(() => {
    if (docid == 0 || docid == "") {
      getdate();
    }
  }, [null]);

  const [uid, setUID] = useState(sessionStorage.getItem("UserID"));
  useEffect(() => {
    setUID(window.location.search.split("&")[0].split("=")[1]);
    try {
      if (window.location.search.split("&")[1].split("=")[0] == "Hid") {
        try {
          setDocid(window.location.search.split("&")[1].split("=")[1]);
          setDocidtype(window.location.search.split("&")[1].split("=")[0]);
          setWatchlistDocid(0);
        } catch (e) {
          setDocid(0);
          ////console.log(e);
        }
      } else {
        try {
          setWatchlistDocid(window.location.search.split("&")[1].split("=")[1]);
          setDocid(0);
        } catch (e) {
          setWatchlistDocid(0);
        }
      }
    } catch {
      setDocid(0);
      setWatchlistDocid(0);
    }
  }, [null]);

  useEffect(() => {
    let url = `${apiHost}/api/Rates/FetchRate`;
    // , "expired"
    // axios.get(url).then((response) => {
    //   let dd = new Date(response.data.time.value);
    //   dd = dd.toString();
    //   let date =
    //     dd.split(" ")[1] + " " + dd.split(" ")[2] + "," + dd.split(" ")[3];
    //   setDdate(date);
    //   let time =
    //     dd.split(" ")[4] +
    //     " " +
    //     dd.split(" ")[6] +
    //     " " +
    //     dd.split(" ")[7] +
    //     " " +
    //     dd.split(" ")[8];
    //   setTime(time);
    // });
    axios.get(url).then((response) => {
      let dd = new Date(response.data.time.value);
      dd = new Date(
        dd.toLocaleString("en-US", {
          timeZone: sessionStorage.getItem("TimeZone"),
        })
      );
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      };
      const formattedDate = dd.toLocaleDateString("en-US", options);
      let date =
        formattedDate.split(",")[0] + "," + formattedDate.split(",")[1];
      setDdate(date);
      let time =
        formattedDate.split(",")[2] +
        `  (${sessionStorage.getItem("TimeZone")})`;
      setTime(time);
    });
  }, [uid]);

  const getWatchlistDocData = async () => {
    let url = `${apiHost}/api/User/GetWatchListByID?Id=${watchlistDocid}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setiseditORder("true");
        settakecurrency(response.data.takeCurrencyInfo);
        setgivecurrency(response.data.giveCurrencyInfo);
        setselectedcountry(response.data.giveCountry);
        setgiveselectedcountry(response.data.takeCountry);
        setwatchlistDocList({ ...response.data });
      })
      .catch((error) => {
        ////console.log(error);
      });
  };
  useEffect(() => {
    setuserdata(sessionStorage.getItem("UserName"));
  }, [uid]);
  useEffect(() => {
    setuserdata(sessionStorage.getItem("UserName"));
    if (watchlistDocid != 0) {
      getWatchlistDocData();
    }
  }, [watchlistDocid]);
  const PutonHold = async () => {
    let checkError = errorCheck();
    let checkExisting = await checkExistingOrder();
    if (checkError == false) {
      //console.log("m");
    } else if (checkExisting) {
    } else {
      setIsLoadingHold(true);
      let giveCityLatLon = {};
      let takeCityLatLon = {};
      let countryCode = getCountryCode(selectedcountry, giveselectedcountry);

      fetch(
        `${apiHost}/api/Orders/GetCoordinates?city=${useselectedcity}&state=${useselectedstate}&country=${selectedcountry}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((ress) => {
          ////console.log(ress.results[0].annotations.DMS);
          let giveLat = ress.latitude.split(" ").join("");
          let giveLng = ress.longitutde.split(" ").join("");
          giveCityLatLon = { lat: giveLat, lon: giveLng };
          fetch(
            `${apiHost}/api/Orders/GetCoordinates?city=${usegiveselectedcity}&state=${usegiveselectedstate}&country=${giveselectedcountry}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
            .then((res1) => res1.json())
            .then((ress1) => {
              let takeLat = ress1.latitude.split(" ").join("");
              let takeLng = ress1.longitutde.split(" ").join("");
              takeCityLatLon = { lat: takeLat, lon: takeLng };
              setDoc(`create`, {
                SerialNo: serial_no + 1,
                Userid: uid,
                Username: sessionStorage.getItem("UserName"),
                GiveAmount: giveamount,
                GiveCity: useselectedcity,
                GiveState: useselectedstate,
                GiveCityLatLon: giveCityLatLon,
                GiveCountry: selectedcountry,
                GiveCountryCode:
                  countryCode[0] == "" ? selectedcountry : countryCode[0],
                TakeAmount: takeamount,
                TakeCity: usegiveselectedcity,
                TakeCityLatLon: takeCityLatLon,
                TakeState: usegiveselectedstate,
                TakeCountry: giveselectedcountry,
                TakeCountryCode:
                  countryCode[1] == "" ? giveselectedcountry : countryCode[1],
                TakeCurrency: takecurrency.split(" ")[0],
                TakeCurrencyInfo: takecurrency,
                GiveSourceCash: Cash,
                GiveSourceBank: Bank,
                MatchInvite: false,
                MatchAccept: false,
                MatchReject: false,
                GiveCurrency: givecurrency.split(" ")[0],
                GiveCurrencyInfo: givecurrency,
                TakeThroughCash: takeCash,
                TakeThroughBank: takeBank,
                AdditionalAmount: AddAmount,
                UntilGoodDate: UntilGoodDate,
                // untilcheck: goodcheck,
                HoldTime: date,
                ExpiredTome: expirytime,
                ExpiredTime: expirytime,
                status: "hold",
                matched: false,
                inFinalize: false,
                favBy: 0,
                timeCreated: Date.now(),
              }).then(() => {
                setIsLoadingHold(false);
                // navigate(`/OrderCom?ID=${uid}&t=${Date.now()}`);
              });
            });
        });
    }
  };
  const Updatedoc = async () => {
    let checkError = errorCheck();
    let checkExisting = await checkExistingOrder();
    if (checkError == false) {
      //console.log("m");
    } else if (checkExisting) {
    } else {
      // window.scroll(0, 0);
      setIsLoading(true);
      let giveCityLatLon = {};
      let takeCityLatLon = {};
      let countryCode = getCountryCode(selectedcountry, giveselectedcountry);
      fetch(
        `${apiHost}/api/Orders/GetCoordinates?city=${useselectedcity}&state=${useselectedstate}&country=${selectedcountry}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((ress) => {
          ////console.log(ress.results[0].annotations.DMS);
          let giveLat = ress.latitude.split(" ").join("");
          let giveLng = ress.longitutde.split(" ").join("");

          giveCityLatLon = { lat: giveLat, lon: giveLng };
          fetch(
            `${apiHost}/api/Orders/GetCoordinates?city=${usegiveselectedcity}&state=${usegiveselectedstate}&country=${giveselectedcountry}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
            .then((res) => res.json())
            .then((ress) => {
              ////console.log(ress.results[0].annotations.DMS);
              let takeLat = ress.latitude.split(" ").join("");
              let takeLng = ress.longitutde.split(" ").join("");
              takeCityLatLon = { lat: takeLat, lon: takeLng };
              setDoc("update", {
                Userid: uid,
                orderId: window.location.href.split("=")[2].replace("&t", ""),
                Username: userdata,
                GiveAmount: giveamount,
                GiveCity: useselectedcity,
                GiveCityLatLon: giveCityLatLon,
                GiveState: useselectedstate,
                GiveCountry: selectedcountry,
                GiveCountryCode:
                  countryCode[0] == "" ? selectedcountry : countryCode[0],
                TakeAmount: takeamount,
                TakeCity: usegiveselectedcity,
                TakeState: usegiveselectedstate,
                TakeCityLatLon: takeCityLatLon,
                TakeCountry: giveselectedcountry,
                TakeCountryCode:
                  countryCode[1] == "" ? giveselectedcountry : countryCode[1],
                GiveSourceCash: Cash,
                GiveSourceBank: Bank,
                MatchInvite: false,
                MatchAccept: false,
                MatchReject: false,
                GiveCurrency: givecurrency.split(" ")[0],
                GiveCurrencyInfo: givecurrency,
                TakeThroughCash: takeCash,
                TakeThroughBank: takeBank,
                AdditionalAmount: AddAmount,
                ActivateTime: date,
                UntilGoodDate: UntilGoodDate,
                ExpiredTome: UntilGoodDate,
                status: "active",
                // untilcheck: goodcheck,
              }).then(() => {
                setIsLoading(false);
                let temp = sessionStorage.getItem("path");
                if (temp == "/OrderCom") {
                  navigate(`/OrderCom?ID=${uid}&t=${Date.now()}`);
                } else {
                  navigate(
                    `/OrderFXHome?ID=${uid}?orderId=${
                      window.location.href.split("=")[2]
                    }`
                  );
                }
              });
            });
        });
    }
  };
  const getCountryCode = (give, take) => {
    let result = ["", ""];
    for (let index = 0; index < Codes.length; index++) {
      const element = Codes[index][0];
      if (element == give) {
        result[0] = Codes[index][1];
      }
      if (element == take) {
        result[1] = Codes[index][1];
      }
    }
    return result;
  };

  const Activate = async (userId) => {
    let checkError = errorCheck();
    let checkExisting = false;
    if (!props.is1FX) {
      checkExisting = await checkExistingOrder();
    }
    if (checkError == false) {
      //console.log("m");
    } else if (checkExisting) {
    } else {
      setIsLoading(true);
      activateBtn.current.disabled = true;

      let giveCityLatLon = {};
      let takeCityLatLon = {};
      fetch(
        `${apiHost}/api/Orders/GetCoordinates?city=${useselectedcity}&state=${useselectedstate}&country=${selectedcountry}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((ress) => {
          console.log(ress);
          ////console.log(ress.results[0].annotations.DMS);
          let giveLat = ress.latitude.split(" ").join("");
          let giveLng = ress.longitutde.split(" ").join("");

          giveCityLatLon = { lat: giveLat, lon: giveLng };
          fetch(
            `${apiHost}/api/Orders/GetCoordinates?city=${usegiveselectedcity}&state=${usegiveselectedstate}&country=${giveselectedcountry}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
            .then((res) => res.json())
            .then((ress) => {
              ////console.log(ress.results[0].annotations.DMS)
              let takeLat = ress.latitude.split(" ").join("");
              let takeLng = ress.longitutde.split(" ").join("");
              // takeLat =
              //   parseFloat(takeLat.split("°")[0]) +
              //   parseFloat(takeLat.split("°")[1].split("'")[0]) / 60 +
              //   parseFloat(takeLat.split("°")[1].split("'")[1]) / 3600;
              // takeLng =
              //   parseFloat(takeLng.split("°")[0]) +
              //   parseFloat(takeLng.split("°")[1].split("'")[0]) / 60 +
              //   parseFloat(takeLng.split("°")[1].split("'")[1]) / 3600;
              takeCityLatLon = { lat: takeLat, lon: takeLng };
              let countryCode = getCountryCode(
                selectedcountry,
                giveselectedcountry
              );
              let obj = {
                SerialNo: serial_no + 1,
                Userid: userId,
                Username: userdata,
                GiveAmount: giveamount,
                GiveCity: useselectedcity,
                GiveCityLatLon: giveCityLatLon,
                GiveState: useselectedstate,
                GiveCountry: selectedcountry,
                GiveCountryCode:
                  countryCode[0] == "" ? selectedcountry : countryCode[0],
                TakeAmount: takeamount,
                TakeCity: usegiveselectedcity,
                TakeCityLatLon: takeCityLatLon,
                TakeState: usegiveselectedstate,
                TakeCountry: giveselectedcountry,
                TakeCountryCode:
                  countryCode[1] == "" ? giveselectedcountry : countryCode[1],
                TakeCurrency: takecurrency.split(" ")[0],
                TakeCurrencyInfo: takecurrency,
                GiveSourceCash: Cash,
                GiveSourceBank: Bank,
                MatchInvite: false,
                MatchAccept: false,
                MatchReject: false,
                GiveCurrency: givecurrency.split(" ")[0],
                GiveCurrencyInfo: givecurrency,
                TakeThroughCash: takeCash,
                TakeThroughBank: takeBank,
                AdditionalAmount: AddAmount,
                UntilGoodDate: UntilGoodDate,
                // untilcheck: goodcheck,
                status: "active",
                ActivateTime: date,
                ExpiredTome: expirytime,
                matched: false,
                favBy: 0,
                inFinalize: false,
                timeCreated: Date.now(),
              };
              setDoc(`create`, obj).then((res) => {
                SignalRService.sendMatches(uid, obj);
                localStorage.setItem(
                  "ordersCount",
                  parseInt(localStorage.ordersCount) + 1
                );
                setIsLoading(false);
                if (props.is1FX) {
                  props.setCustomer(1);
                } else if (props.isHomeFX) {
                  window.location.reload();
                } else {
                  navigate(`/OrderCom?ID=${userId}&t=${Date.now()}`);
                }
              });
            });
        });
    }
  };

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  ////////////////////////Location and COuntry/////////////////////////

  useEffect(() => {
    getCountry();
  }, [null]);

  const getCountry = () => {
    let countries = cityData.map((d) => d.country);
    countries = [...new Set(countries)];
    countries = countries.sort();
    setCountries(countries);
  };

  useEffect(() => {
    getState();
  }, [selectedcountry]);

  const getState = () => {
    let states = cityData
      .filter((x) => {
        return x.country == selectedcountry;
      })
      .map((obj) => {
        return obj.subCountry;
      });
    states = [...new Set(states)];
    states = states.sort();
    setStateArray(states);
    setgiveStates(states);
  };
  useEffect(() => {
    getCity();
  }, [useselectedstate]);

  const getCity = () => {
    let cities = cityData
      .filter((x) => {
        return x.subCountry == useselectedstate;
      })
      .map((obj) => {
        return obj.name;
      });
    cities = cities.sort();
    setgiveCities(cities);
  };

  ////////take///////

  useEffect(() => {
    gettakeState();
  }, [giveselectedcountry]);

  const gettakeState = () => {
    let states = cityData
      .filter((x) => {
        return x.country == giveselectedcountry;
      })
      .map((obj) => {
        return obj.subCountry;
      });
    states = [...new Set(states)];
    states = states.sort();
    setStateArray(states);
    settakeStates(states);
  };

  useEffect(() => {
    gettakeCity();
  }, [usegiveselectedstate]);

  const gettakeCity = () => {
    let cities = cityData
      .filter((x) => {
        return x.subCountry == usegiveselectedstate;
      })
      .map((obj) => {
        return obj.name;
      });
    cities = cities.sort();
    settakeCities(cities);
  };
  ///////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (
      takecurrency === "null" ||
      giveamount === "null" ||
      givecurrency === "null"
    ) {
      setOutput("");
      setformatoutput("");
      setsuggestion("");
    }
  }, [givecurrency, giveamount, takecurrency]);

  useEffect(() => {
    if (giveamount == undefined) {
      setgiveamount("");
      settakeamount("");
    }
  }, [orderslist, null]);

  function checkExistingOrder() {
    return new Promise((resolve, reject) => {
      if (
        takecurrency != null &&
        givecurrency != null &&
        usegiveselectedcity != null &&
        useselectedcity != null
      ) {
        let url = `${apiHost}/api/Orders/GetDuplicateOrder?GiveCurrency=${
          givecurrency.split(" ")[0]
        }&TakeCurrency=${
          takecurrency.split(" ")[0]
        }&userId=${uid}&TakeCity=${usegiveselectedcity}&GiveCity=${useselectedcity}`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            // //console.log(
            //   window.location.search.substring(1).split("&").length === 1
            // );
            if (
              response.data != "" &&
              window.location.search.substring(1).split("&").length === 1
            ) {
              setModalID(response.data);
              setShowModal(true);
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    });
  }

  useEffect(() => {
    if (!props.is1FX) {
      window.scrollTo(0, 100);
      document
        .getElementById("startingDiv")
        .scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, []);

  useEffect(() => {
    checkExistingOrder();
  }, [takecurrency, givecurrency, usegiveselectedcity, useselectedcity]);

  const confirmModal = () => {
    setShowModal(false);
    sessionStorage.setItem("path", window.location.pathname);
    //navigate(`/Createorder?ID=${uid}&Hid=${props.id}`);
    navigate(`/Createorder?ID=${uid}&Hid=${modalID}&t=${Date.now()}`);
    window.location.reload();
  };

  // const confirmModal = async () => {
  // };

  useEffect(() => {
    if (isQuickOrder) {
      let url = `${apiHost}/api/User/GetUserById?UserId=${uid}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((d) => {
          setgiveselectedcountry(d.data.country);
          setselectedcountry(d.data.country);
          setgiveuseState(d.data.province);
          setuseState(d.data.province);
          setuseselectedcity(d.data.city);
          setgiveuseselectedcity(d.data.city);
        });
    }
  }, [isQuickOrder]);
  ////////////////////////////////////////////////OrderFx////////////////////////////
  const Checkemail = async () => {
    return new Promise((resolve, reject) => {
      let Email = email.toLowerCase();
      let url = `${apiHost}/api/User/EmailExist?email=${Email}`;
      axios.get(url).then(async (response) => {
        resolve(response.data);
      });
    });
  };

  const sendEmail = async (e) => {
    Checkemail().then((d) => {
      if (!d) {
        document.getElementById("codebutton").style.display = "none";
        document.getElementById("emailcheck").style.display = "none";
        if (checkcode == Verifycode) {
          document.getElementById("email").disabled = false;
          document.getElementById("codebutton").className = "verifybtn";
          document.getElementById("codebutton").innerHTML = "verify";
          document.getElementById("tick").style.display = "none";
          setcheckcode(" ");
          setverifycode("");
        } else {
          var val = Math.floor(1000 + Math.random() * 9000);
          setverifycode(val);
          document.getElementById("tick").style.display = "none";
          setborder(false);
          setcheckcode(" ");
          if (email.length === 0) {
            setEmailerror("Please Enter E-mail before verify");
            document.getElementById("root").scroll(0, 500);
            setTimeout(() => {
              setEmailerror("");
            }, 5000);
          } else {
            let url = `${apiHost}/api/User/GetUserByEmail?Email=${email}`;
            axios.get(url).then(async (response) => {
              const data = [];
              response.data.users.map((doc) => {
                data.push({
                  ...doc,
                  id: doc.id,
                  Email: doc.Email,
                });
              });
              if (data.length >= 1) {
                document.getElementById("emailcheck").style.display = "block";
                setTimeout(() => {
                  document.getElementById("emailcheck").style.display = "none";
                }, 6000);
              } else {
                let url = `${apiHost}/api/Email/SendVerificationEmail?toEmail=${email}&code=${val}`;
                axios
                  .post(url)
                  .then(async (response) => {
                    setshowform(true);
                    setcheckEmail(email);

                    document.getElementById("codebutton").style.display =
                      "none";
                    document.getElementById("sendcodemsg").style.display =
                      "block";
                    setTimeout(() => {
                      document.getElementById("sendcodemsg").style.display =
                        "none";
                    }, 6000);
                  })
                  .catch((err) => {
                    //console.log(err);
                  })
                  .catch((err) => {
                    //console.log(err);
                  });
              }
            });
          }
        }
      } else {
        document.getElementById("emailcheck").style.display = "block";
        document.getElementById("codebutton").style.display = "block";
        // setTimeout(() => {
        //   document.getElementById("emailcheck").style.display = "none";
        // }, 3000)
      }
    });
    e.preventDefault();
  };

  const checkUserName = async () => {
    return new Promise((resolve, reject) => {
      let url = `${apiHost}/api/User/UserNameExist?name=${UserName}`;
      axios.get(url).then(async (response) => {
        if (response.data) {
          document.getElementById("takenerr").style.display = "block";
        } else {
          document.getElementById("takenerr").style.display = "none";
        }
        resolve(response.data);
      });
    });
  };

  useEffect(() => {
    if (UserName != "") {
      setusernameerr("");
    }
  }, [UserName]);

  useEffect(() => {
    if (email != 0) {
      setEmailerror("");
    }
  }, [email]);

  // useEffect(()=>{},[password])
  // useEffect(()=>{},[Verifycode])

  const ifFxSignup = () => {
    let errorcheck = errorCheck();
    let errcount = 0;
    if (!errorcheck) {
    }
    if (UserName == "") {
      setusernameerr("Missing Field");
      document.getElementById("userName").focus();
      errcount += 1;
    }
    if (UserName.toLowerCase().includes("mobala")) {
      setusernameerr("Username cannot include mobala");
      document.getElementById("userName").focus();
      errcount += 1;
    }
    if (UserName.length > 12) {
      document.getElementById("userName").focus();
      document.getElementById("takenlenerr").style.display = "block";
      errcount += 1;
    }
    if (email.length === 0) {
      setEmailerror("Missing Field");
      document.getElementById("email").focus();
      errcount += 1;
    }
    if (emailverify === false) {
      setEmailerror("Email is not verified");
      document.getElementById("verificationCode").focus();
      errcount += 1;
    }
    if (Verifycode != checkcode) {
      errcount += 1;
    }
    if (password.length === 0) {
      setPassworderror("Missing Field");
      document.getElementById("password").focus();
      errcount += 1;
    }
    if (password != confirmpassword) {
      setpasscheck("Password Mismatch");
      document.getElementById("confirmPassword").focus();
      errcount += 1;
    }
    if (password.length < 7) {
      setPassworderror(
        "Password must be 7 or more characters in length and minimum 1 special character"
      );
      document.getElementById("password").focus();
      errcount += 1;
    }
    if (selectedlogincountry == "") {
      setcountryerr("Missing Field");
      document.getElementById("root").scroll(0, 0);
      document.getElementById("country").focus();
      errcount += 1;
    }
    if (errcount == 0) {
      let data = {
        Email: email.toLocaleLowerCase(),
        pwd: password,
        Country: selectedlogincountry,
        UserName: UserName,
        date: new Date().toLocaleString(),
        Image: "",
        IDImage: "",
        DocumentImage: "",
        EndDocumentImage: "",
      };
      let url = `${apiHost}/api/Authentication/SignUp`;
      axios
        .post(url, data)
        .then((response) => {
          if (response.data.split(" ")[0] == "SignedUp") {
            setUID(response.data.split(" ")[1]);
            Activate(response.data.split(" ")[1]);
          }
        })
        .catch((error) => {
          //console.log(error);
          setIsLoading(false);
        });
    }
  };

  const getRates = () => {
    let url = `${apiHost}/api/Rates/FetchRate`;
    axios.get(url).then((response) => {
      setRates(response.data.rates);
    });
  };

  const checkAmountLength = (amount, suggested) => {
    var suggestion = suggested.replaceAll(",", "");
    var min_amount = (suggestion * 90) / 100;
    var max_amount = (suggestion * 110) / 100;
    var amount = amount.replaceAll(",", "");
    // //console.log("amount=>", amount, " min_amount=>", min_amount, " max_amount=>", max_amount)
    if (amount > max_amount) return false;
    else if (amount < min_amount) return true;
    else return null;
  };

  const updateSuggestions = () => {
    if (rates != null) {
      let giveAmount = giveamount.toString();
      let takeAmount = takeamount.toString();
      let give = givecurrency.split(" ")[0];
      let take = takecurrency.split(" ")[0];
      let g = rates.filter((x) => x.name == give)[0];
      let t = rates.filter((x) => x.name == take)[0];
      let givecuramm = g["rates"] / t["rates"];
      let takecuramm = t["rates"] / g["rates"];
      let amountgive = giveAmount.replaceAll(",", "");
      let amounttake = takeAmount.replaceAll(",", "");
      let takeout = takecuramm * 0.99 * amountgive;
      let takeoutshow = takecuramm * amountgive;
      // takeout = takeout.toFixed(0)
      let giveout = (givecuramm / 0.99) * amounttake;
      let giveoutshow = givecuramm * amounttake;
      setTakeSuggestion(takeoutshow.toLocaleString("en-US"));
      setGiveSuggestion(giveoutshow.toLocaleString("en-US"));
      // giveout = giveout.toFixed(0)
      setformatoutputGive(givecuramm.toLocaleString("en-US"));
      setformatoutput(takecuramm.toLocaleString("en-US"));
      setOutput(
        takeout.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
      setOutputGive(
        giveout.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
      return {
        giveSuggestion: giveout.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        takeSuggestion: takeout.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      };
    } else {
      return {
        giveSuggestion: "0",
        takeSuggestion: "0",
      };
    }
  };
  const getGiveSuggestions = () => {
    if (
      givecurrency != "" &&
      takecurrency != "" &&
      takeamount != "" &&
      givecurrency != null &&
      takecurrency != null &&
      takeamount != null
    ) {
      let suggestions = updateSuggestions();
      setShowTakeSuggestion(false);
      setShowGiveSuggestion(true);
      let giveCheck = checkAmountLength(giveamount, suggestions.giveSuggestion);
      setgiveLengtherr(giveCheck);
      settakelengtherr(null);
      setAmountSuretyerr("");
      setAmountSuretyerrGive(giveCheck == null ? "" : amountSuretyerrGive);
    }
  };
  const getTakeSuggestions = () => {
    if (
      givecurrency != "" &&
      takecurrency != "" &&
      giveamount != "" &&
      givecurrency != null &&
      takecurrency != null &&
      giveamount != null
    ) {
      let suggestions = updateSuggestions();
      setShowGiveSuggestion(false);
      setShowTakeSuggestion(true);
      let takeCheck = checkAmountLength(takeamount, suggestions.takeSuggestion);
      settakelengtherr(takeCheck);
      setgiveLengtherr(null);
      setAmountSuretyerr(takeCheck == null ? "" : amountSuretyerr);
      setAmountSuretyerrGive("");
    }
  };
  useEffect(() => {
    getGiveSuggestions();
  }, [giveamount]);

  useEffect(() => {
    getTakeSuggestions();
  }, [takeamount, rates]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="d-flex flex-column min-vh-100">
      <div>
        <div id="startingDiv" className="">
          <Row className="centeritem">
            <Col
              md={props.is1FX || props.isHomeFX ? 10 : 6}
              className="formbg"
            >
              <Form className="forminput radius createOrder">
                {!props.is1FX ? (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          id="tooltip-disabled"
                          bsPrefix="custom-tooltip"
                          style={{ position: "absolute" }}
                        >
                          At your registered location
                        </Tooltip>
                      }
                      placement="top-start" // Set the placement to top-start
                    >
                      <h6 className="quickHeading fw8">Quick Order: </h6>
                    </OverlayTrigger>
                    <input
                      onClick={() => setisQuickOrder(!isQuickOrder)}
                      checked={isQuickOrder}
                      type={"checkbox"}
                      className="chkbox "
                    />
                  </Row>
                ) : null}
                <Modal show={showModal} onHide={hideModal} size="md">
                  <Modal.Header>
                    <Modal.Title>
                      Duplicate Orders are not permitted
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p style={{ fontSize: "17px" }}>
                      There is an Order present with same Currencies and
                      Locations. Do you want to edit that order?
                    </p>
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="default"
                      style={{ width: "20%" }}
                      onClick={confirmModal}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="primary"
                      style={{ width: "20%" }}
                      onClick={hideModal}
                    >
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* <--------------------------------I Want to Give :--------------------> */}
               <h1 style={{fontSize:"14px",fontWeight:"bold",marginBottom:"16px",whiteSpace:"nowrap",textAlign:"center"}}>Create your order to get Matching offers on Mobala and Finalize them after negotiation</h1>

                <h6 className="fw8 mb-3 relative">
                  <OverlayTrigger
                    overlay={<Tooltip
                      id="tooltip-disabled"
                      bsPrefix="custom-tooltip"
                      style={{ position: "absolute" }}
                    >Sell</Tooltip>}
                    placement="left"
                  >
                    <span style={{ cursor: "default" }}>I Want to Give:</span>
                  </OverlayTrigger>
                </h6>

                <div className="border p-3">
                  <Row className="textcenter">
                    <Col>
                      <InputGroup className="mb-3">
                        <Form.Select
                          id="giveCurrency"
                          style={givecurrencyerr != "" ? styles.err : null}
                          onChange={(e) => {
                            setgivecurrency(e.target.value);
                          }}
                          onClick={(e) => {
                            setgivecurrencyerror("");
                          }}
                          value={givecurrency}
                          disabled={iseditORder || mcOrderId}
                          aria-label="Default select example"
                        >
                          <option value="">Currency</option>
                          {Currencies.map((item, key) => (
                            <option value={item} key={key}>
                              {item}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Col>

                    <Col className="mb-3" style={{ textAlign: "end" }}>
                      {/* <InputGroup className="">
                        <input
                          className="giveamount form-control"
                          // style={}
                          style={
                            giveamounterr != ""
                              ? styles.err
                              : { textAlign: "right" }
                          }
                          thousandseparator="true"
                          id="giveAmountInput"
                          autoComplete="off"
                          onFocus={getGiveSuggestions}
                          onInput={(e) => {
                            let val = e.target.value;
                            const cursorPosition = e.target.selectionStart; // Store cursor position
                            if (val === "") {
                              setgiveamount(val);
                              return;
                            }
                            setgiveamount(
                              parseInt(val.replaceAll(",", "")).toLocaleString(
                                "en-US"
                              )
                            );
                            e.target.setSelectionRange(
                              cursorPosition,
                              cursorPosition
                            );
                          }}
                          onClick={(e) => {
                            setgiveamounterr("");
                          }}
                          onKeyPress={(e) => {
                            let code = e.charCode;
                            if (code < 48 || code > 57) {
                              e.preventDefault();
                            }
                            if (e.target.value.length >= 9) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={8}
                          value={giveamount}
                          aria-label="Amount"
                          placeholder="Amount"
                        />
                      </InputGroup> */}
                      <InputGroup className="">
  <input
    className="giveamount form-control"
    style={giveamounterr !== "" ? styles.err : { textAlign: "right" }}
    thousandseparator="true"
    id="giveAmountInput"
    autoComplete="off"
    onFocus={getGiveSuggestions}
    onInput={(e) => {
      let val = e.target.value;

      // Remove non-digit characters using regex and allow only digits
      val = val.replace(/\D/g, "");

      const cursorPosition = e.target.selectionStart; // Store cursor position

      // If the value is empty, set giveamount to empty
      if (val === "") {
        setgiveamount(val);
        return;
      }

      // Format the number with thousand separators
      setgiveamount(parseInt(val, 10).toLocaleString("en-US"));

      // Maintain the cursor position after formatting
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }}
    onClick={() => {
      setgiveamounterr("");
    }}
    onKeyPress={(e) => {
      let code = e.charCode;

      // Prevent any non-digit input
      if (code < 48 || code > 57) {
        e.preventDefault();
      }

      // Prevent more than 9 digits from being entered
      if (e.target.value.length >= 9) {
        e.preventDefault();
      }
    }}
    maxLength={8}
    value={giveamount}
    aria-label="Amount"
    placeholder="Amount"
  />
</InputGroup>

                    </Col>
                  </Row>
                  <Row
                    style={
                      showGiveSuggestion
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  >
                    {giveLengtherr == null ? null : (
                      <p
                        className="text-right"
                        id="limiterr1"
                        style={{
                          fontSize: "15px",
                          color: "#ff3f3f",
                          fontStyle: "italic",
                        }}
                      >
                        {giveLengtherr
                          ? "Entered amount is low"
                          : "Entered amount is high"}
                      </p>
                    )}
                    <p
                      className="text-right"
                      style={{
                        margin: 0,
                      }}
                    >
                      Go For:{" "}
                      <span style={{ color: "red" }}>
                        {outputGive == "NaN" ? null : outputGive}{" "}
                      </span>
                    </p>
                    <p
                      className="text-right"
                      style={{
                        margin: 0,
                      }}
                    >
                      {" "}
                      Best Google Amount:{" "}
                      <span style={{ color: "red" }}>
                        {giveSuggestion === "NaN" ? null : giveSuggestion}{" "}
                      </span>
                    </p>
                    <p
                      className="text-right"
                      style={{
                        margin: 0,
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {formatoutputGive === "NaN"
                        ? null
                        : `1 ${takecurrency} =  ${formatoutputGive} ${givecurrency}`}
                    </p>

                    <p
                      className="text-right"
                      style={{
                        margin: 0,
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {formatoutput === "NaN"
                        ? null
                        : `1 ${givecurrency} =  ${formatoutput} ${takecurrency}`}
                    </p>
                    <div
                      id="amountsuretygive"
                      style={
                        giveLengtherr == null
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      <input
                        onClick={handleAmountSuretyGive}
                        checked={amountSuretyGive}
                        type={"checkbox"}
                        className=""
                      />{" "}
                      Are you sure of this amount
                    </div>
                    <label style={{ marginBottom: "10px", color: "red" }}>
                      {amountSuretyerrGive}
                    </label>
                  </Row>

                  <Row className="textcenter">
                    <Col xs={12} sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Select
                          id="givecountry"
                          style={
                            error?.giveCountryErr != "" ? styles.err : null
                          }
                          onChange={(e) => {
                            setselectedcountry(e.target.value);
                            setuseselectedcity("");
                            setuseState("");
                            let tempListOfCountries = [...Countries];
                            //console.log(tempListOfCountries);
                            for (
                              let index = 0;
                              index < tempListOfCountries.length;
                              index++
                            ) {
                              if (
                                e.target.value == tempListOfCountries[index]
                              ) {
                                setgiveCountryId(index);
                              }
                            }
                          }}
                          onClick={(e) => {
                            setError({ ...error, giveCountryErr: "" });
                          }}
                          aria-label="Default select example"
                          value={selectedcountry}
                        >
                          <option value="">Country</option>
                          {Countries.map((item, key) => (
                            <option value={item} key={key}>
                              {item}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {/* <p className="text-danger mb-3">
                        {error?.giveCountryErr}
                      </p> */}
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Select
                          id="givestate"
                          style={error?.giveStateErr != "" ? styles.err : null}
                          onChange={(e) => {
                            setuseselectedcity("");
                            setuseState(
                              useselectedstate === useselectedstate
                                ? e.target.value
                                : useselectedstate
                            );
                            for (
                              let index = 0;
                              index < stateArray.length;
                              index++
                            ) {
                              if (e.target.value == stateArray[index]) {
                                setgivestateId(index);
                              }
                            }
                          }}
                          onClick={(e) => {
                            setError({ ...error, giveStateErr: "" });
                          }}
                          aria-label="Default select example"
                          value={useselectedstate}
                        >
                          <option value="">State/Province</option>
                          {giveStates.map((items, key) => (
                            <option value={items} key={key}>
                              {items}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {/* <p className="text-danger mb-3">{error?.giveStateErr}</p> */}
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Select
                          id="givecity"
                          style={error?.giveCityErr != "" ? styles.err : null}
                          onChange={(e) =>
                            setuseselectedcity(
                              useselectedcity === useselectedcity
                                ? e.target.value
                                : useselectedcity
                            )
                          }
                          onClick={(e) => {
                            setError({ ...error, giveCityErr: "" });
                          }}
                          aria-label="Default select example"
                          value={useselectedcity}
                        >
                          <option value="">City</option>
                          <option
                            style={{
                              color: "black",
                              backgroundColor: "#a2e99d",
                            }}
                            value="Any City"
                          >
                            Any City
                          </option>
                          {giveCities.map((items, key) => (
                            <option value={items} key={key}>
                              {items}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {/* <p className="text-danger mb-3">{error?.giveCityErr}</p> */}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mt-2">
                      <input
                        onClick={handleCashSource}
                        checked={Cash}
                        type={"checkbox"}
                        className=""
                      />{" "}
                      Cash
                      <input
                        onClick={handleBankSource}
                        checked={Bank}
                        type={"checkbox"}
                        className="chkbox ml-3"
                      />{" "}
                      Bank
                    </Col>
                  </Row>
                </div>
                {/* <--------------------------------I Want to Take :--------------------> */}
                <h6 className="fw8 mt-3 mb-3 relative">
                  <OverlayTrigger
                    overlay={<Tooltip
                      id="tooltip-disabled"
                      bsPrefix="custom-tooltip"
                      style={{ position: "absolute" }}
                    >Buy/Send</Tooltip>}
                    placement="top-start"
                  >
                    <span style={{ cursor: "default" }}>I Want to Take:</span>
                  </OverlayTrigger>
                </h6>
                
                <div className="border p-3 mt-3">
                  <Row>
                    <Col></Col>
                  </Row>

                  <Row className="textcenter">
                    <Col>
                      <InputGroup className="mb-3">
                        <Form.Select
                          style={takecurrencyerr != "" ? styles.err : null}
                          onChange={(e) => {
                            settakecurrency(e.target.value);
                            ////console.log(`Give Currency is ${e.target.value}`);
                          }}
                          value={takecurrency}
                          aria-label="Default select example"
                          disabled={iseditORder || mcOrderId}
                          onClick={(e) => {
                            settakecurrencyerr("");
                          }}
                        >
                          <option value="">Currency</option>
                          {Currencies.map((item, key) => (
                            <option value={item} key={key}>
                              {item}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col style={{ textAlign: "end" }}>
                      {/* <InputGroup>
                        <input
                          id="takeAmount"
                          className="form-control"
                          style={
                            takeamounterr != ""
                              ? styles.err
                              : { textAlign: "right" }
                          }
                          autoComplete="off"
                          // as={NumberFormat}
                          onFocus={getTakeSuggestions}
                          thousandseparator="true"
                          onInput={(e) => {
                            let val = e.target.value;
                            const cursorPosition = e.target.selectionStart; // Store cursor position
                            if (val === "") {
                              settakeamount(val);
                              return;
                            }
                            settakeamount(
                              parseInt(val.replaceAll(",", "")).toLocaleString(
                                "en-US"
                              )
                            );
                            e.target.setSelectionRange(
                              cursorPosition,
                              cursorPosition
                            );
                          }}
                          onClick={(e) => {
                            settakeamounterr("");
                          }}
                          onKeyPress={(e) => {
                            let code = e.charCode;
                            if (code < 48 || code > 57) {
                              e.preventDefault();
                            }
                            if (e.target.value.length >= 9) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={8}
                          value={takeamount}
                          aria-label="Amount"
                          placeholder="Amount"
                        />
                      </InputGroup> */}
                      <InputGroup>
  <input
    id="takeAmount"
    className="form-control"
    style={takeamounterr !== "" ? styles.err : { textAlign: "right" }}
    autoComplete="off"
    onFocus={getTakeSuggestions}
    thousandseparator="true"
    onInput={(e) => {
      let val = e.target.value;

      // Remove non-digit characters using regex and allow only digits
      val = val.replace(/\D/g, "");

      const cursorPosition = e.target.selectionStart; // Store cursor position

      if (val === "") {
        settakeamount(val);
        return;
      }

      // Format the number with thousand separators
      settakeamount(parseInt(val, 10).toLocaleString("en-US"));

      // Maintain the cursor position after formatting
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }}
    onClick={() => {
      settakeamounterr("");
    }}
    onKeyPress={(e) => {
      let code = e.charCode;

      // Prevent any non-digit input
      if (code < 48 || code > 57) {
        e.preventDefault();
      }

      // Prevent more than 9 digits from being entered
      if (e.target.value.length >= 9) {
        e.preventDefault();
      }
    }}
    maxLength={8}
    value={takeamount}
    aria-label="Amount"
    placeholder="Amount"
  />
</InputGroup>

                      <span
                        id="takelengtherr"
                        style={{
                          display: "none",
                          fontSize: "15px",
                          color: "#ff3f3f",
                          fontStyle: "italic",
                        }}
                      >
                        max 9,999,999
                      </span>
                      {/* <label style={{ marginBottom: "10px", color: "red" }}>
                        {takeamounterr}
                      </label> */}
                    </Col>
                  </Row>
                  <Row
                    id="takecheck"
                    style={
                      showTakeSuggestion
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  >
                    {takelengtherr == null ? null : (
                      <p
                        className="text-right"
                        id="limiterr"
                        style={{
                          fontSize: "15px",
                          color: "#ff3f3f",
                          fontStyle: "italic",
                        }}
                      >
                        {takelengtherr
                          ? "Entered amount is low"
                          : "Entered amount is high"}
                      </p>
                    )}
                    <p
                      style={{
                        margin: 0,
                      }}
                      className="text-right"
                    >
                      Go For:{" "}
                      <span style={{ color: "red" }}>
                        {output === "NaN" ? null : output}{" "}
                      </span>
                    </p>
                    <p
                      style={{
                        margin: 0,
                      }}
                      className="text-right"
                    >
                      Best Google Amount:{" "}
                      <span style={{ color: "red" }}>
                        {takeSuggestion === "NaN" ? null : takeSuggestion}{" "}
                      </span>
                    </p>
                    <p
                      className="text-right"
                      style={{
                        margin: 0,
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {formatoutput === "NaN"
                        ? null
                        : `1 ${givecurrency} =  ${formatoutput} ${takecurrency}`}
                    </p>
                    <p
                      className="text-right"
                      style={{
                        margin: 0,
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {formatoutputGive === "NaN"
                        ? null
                        : `1 ${takecurrency} =  ${formatoutputGive} ${givecurrency}`}
                    </p>
                    <div
                      id="amountsurety"
                      style={
                        takelengtherr == null
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      <input
                        onClick={handleAmountSurety}
                        checked={amountSurety}
                        type={"checkbox"}
                        className=""
                      />{" "}
                      Are you sure of this amount
                    </div>
                    <label style={{ marginBottom: "10px", color: "red" }}>
                      {amountSuretyerr}
                    </label>
                  </Row>

                  <Row className="textcenter">
                    <Col xs={12} sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Select
                          id="takecountry"
                          style={
                            error?.takeCountryErr != "" ? styles.err : null
                          }
                          onChange={(e) => {
                            setgiveselectedcountry(e.target.value);
                            setgiveuseselectedcity("");
                            setgiveuseState("");
                            let tempListOfCountries = [...Countries];
                            for (
                              let index = 0;
                              index < tempListOfCountries.length;
                              index++
                            ) {
                              if (
                                e.target.value == tempListOfCountries[index]
                              ) {
                                settakeCountryId(index);
                              }
                            }
                          }}
                          onClick={(e) => {
                            setError({ ...error, takeCountryErr: "" });
                          }}
                          aria-label="Default select example"
                          value={giveselectedcountry}
                        >
                          <option value="">Country</option>
                          {Countries.map((item, key) => (
                            <option value={item} key={key}>
                              {item}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {/* <p className="text-danger mb-3">
                        {error?.takeCountryErr}
                      </p> */}
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Select
                          id="takestate"
                          style={error?.takeStateErr != "" ? styles.err : null}
                          onChange={(e) => {
                            setgiveuseselectedcity("");
                            setgiveuseState(
                              usegiveselectedstate === usegiveselectedstate
                                ? e.target.value
                                : usegiveselectedstate
                            );
                            for (
                              let index = 0;
                              index < stateArray.length;
                              index++
                            ) {
                              if (e.target.value == stateArray[index]) {
                                settakestateId(index);
                              }
                            }
                          }}
                          onClick={(e) => {
                            setError({ ...error, takeStateErr: "" });
                          }}
                          value={usegiveselectedstate}
                          aria-label="Default select example"
                        >
                          <option value="">State/Province</option>
                          {/* <option
                            style={{
                              color: "black",
                              backgroundColor: "#a2e99d",
                            }}
                            value="Any Province"
                          >
                            Any Province
                          </option> */}
                          {takeStates.map((items, key) => (
                            <option value={items} key={key}>
                              {items}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {/* <p className="text-danger mb-3">{error?.takeStateErr}</p> */}
                    </Col>
                    {/* <h2>{output}</h2> */}
                    <Col xs={12} sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Select
                          id="takecity"
                          style={error?.takeCityErr != "" ? styles.err : null}
                          onChange={(e) =>
                            setgiveuseselectedcity(
                              usegiveselectedcity === usegiveselectedcity
                                ? e.target.value
                                : usegiveselectedcity
                            )
                          }
                          onClick={(e) => {
                            setError({ ...error, takeCityErr: "" });
                          }}
                          aria-label="Default select example"
                          value={usegiveselectedcity}
                        >
                          <option value="">City</option>{" "}
                          <option
                            style={{
                              color: "black",
                              backgroundColor: "#a2e99d",
                            }}
                            value="Any City"
                          >
                            Any City
                          </option>
                          {takeCities.map((items, key) => (
                            <option value={items} key={key}>
                              {items}{" "}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {/* <p className="text-danger mb-3">{error?.takeCityErr}</p> */}
                    </Col>
                  </Row>

                  <Row>
                    {/* <CurrencyConverter
                      from={"USD"}
                      to={"CAD"}
                      value={29}
                      className="form-control"
                    /> */}
                    <Col>
                      <InputGroup
                        style={{ display: "none" }}
                        id="1rsrate"
                        className="mb-3"
                      ></InputGroup>
                    </Col>
                    <Col></Col>
                  </Row>

                  <Row>
                    <Row>
                      <Col className="mt-2">
                        <input
                          onClick={handletakeCashSource}
                          checked={takeCash}
                          type={"checkbox"}
                          className=""
                        />{" "}
                        Cash
                        <input
                          onClick={handletakeBankSource}
                          checked={takeBank}
                          type={"checkbox"}
                          className="chkbox ml-3"
                        />{" "}
                        Bank
                      </Col>
                    </Row>
                  </Row>
                </div>
                {/* <--------------------------------Add Aditional Info--------------------> */}
                <div style={{display:"flex",alignItems:"center",gap:"2px"}}>
                <h6 className="fw8 mt-4">Additional Information: </h6>
                <OverlayTrigger
      overlay={
        <Tooltip
          id="tooltip-disabled"
          bsPrefix="custom-tooltip"
          style={{ position: "absolute", maxWidth: "300px", whiteSpace: "normal" }}
        >
          <p style={{ margin: 0 }}>
           If you are an individual, avoid disclosing your exact location or house ( for security purpose ).
          </p>
        </Tooltip>
      }
      placement="top-start"
    >
      <img src={Iicons} style={{width:"25px",height:"25px",marginTop:"12px"}}/>
    </OverlayTrigger>
                </div>
                <div className="border p-3 mt-3">
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <Form.Control
                          onChange={(e) => {
                            setAddAmount(e.target.value);
                            ////console.log(startDate);
                          }}
                          style={{}}
                          value={AddAmount}
                          aria-label="Like min. or max. amounts you want to  transact"
                          placeholder="For example: add  purpose, place, min. or max. amounts in million / billion etc."
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row md={7}>
                    <Col style={{ textAlign: "right" }}>
                      <div
                        className="d-flex"
                        style={{ textAlign: "right", justifyContent: "end" }}
                      >
                        <p style={{ marginTop: "revert" }}>Expiry</p>
                        &nbsp;&nbsp;
                        <InputGroup style={{ width: "max-content" }}>
                          <DatePicker
                            disabled={datedisable}
                            className="untilgooddate"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              let x = date.toLocaleDateString("en-us", {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric",
                              });
                              x = x.replaceAll("/", "-");
                              setexpirytime(x);
                              SetUntilGoodDate(date.toISOString());
                            }}
                            minDate={new Date()}
                            maxDate={maxDate}
                            placeholderText="Select a date"
                            dateFormat="MM/dd/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            style={{ width: "200px", fontSize: "14px" }}
                          />

                          <div className="datelabel">
                            <label
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                                color: "#00000080",
                                zIndex: 999,
                              }}
                            >
                              mm/dd/yyyy
                            </label>
                          </div>
                        </InputGroup>
                      </div>
                      <p
                        style={{
                          textAlign: "Right",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        All orders expire in 30 days unless specified.
                        <br />
                        Orders in Finalize (negotiation) don't expire.
                      </p>
                    </Col>
                  </Row>
                </div>
                {props.is1FX ? (
                  <>
                    <Row className="mt-3">
                      <Col className="mb-2">
                        <h6 className="fw8">Login Information:</h6>
                      </Col>
                    </Row>
                    <div className="border p-3">
                      <Row>
                        <Row style={{ marginTop: 20 }}>
                          <Col>
                            <label>
                              *Preferred UserName / Display Name (can't be
                              changed later)
                            </label>
                            <Form.Group controlId="PreferredUserName">
                              <Form.Control
                                id="userName"
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setUsername(value);
                                  if (value > 12) {
                                    e.preventDefault();
                                  } else {
                                    document.getElementById(
                                      "takenlenerr"
                                    ).style.display = "none";
                                  }
                                }}
                                style={usernameerr != "" ? styles.err : null}
                                value={UserName}
                                onBlur={(e) => checkUserName(e.target.value)}
                                type="text"
                                onClick={(e) => {
                                  setusernameerr(null);
                                }}
                              />
                            </Form.Group>
                            <h6
                              id="takenerr"
                              style={{ color: "red", display: "none" }}
                            >
                              This Username already taken.
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }} className="mt-3">
                          <lable>Country</lable>
                        </Row>
                        <Row className="textcenter mb-3">
                          <Col sm={6}>
                            <select
                              className="form-select"
                              id="country"
                              style={countryerr != "" ? styles.err : null}
                              onChange={(e) => {
                                setSelectedLoginCountry(e.target.value);
                                // for (let index = 0; index < Countries.length; index++) {
                                //   if (e.target.value == Countries[index]) {
                                //     setselectedcountryID(index);
                                //   }
                                // }
                              }}
                              onClick={(e) => {
                                setcountryerr("");
                              }}
                              aria-label="Default select example"
                              value={selectedlogincountry}
                            >
                              <option value="" key={0}>
                                Country
                              </option>
                              {Countries.map((value, key) => (
                                <option
                                  value={value}
                                  key={key}
                                  hidden={key == 0}
                                >
                                  {value}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col sm={6}></Col>
                        </Row>
                        <Row>
                          <lable>E-mail</lable>
                        </Row>
                        <Col sm={8}>
                          <Form.Group
                            className="mb-3"
                            controlId="E-mail"
                            autocomplete="off"
                          >
                            <Form.Control
                              id="email"
                              className={
                                border === true ? "inputgrennbdr" : null
                              }
                              // style={emailerr != "" ? styles.err : null}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              style={emailerr != "" ? styles.err : null}
                              // onKeyUp={Checkemail}
                              value={email}
                              name="user_email"
                              type="text"
                              autocomplete="off"
                              onClick={(e) => {
                                //let value = e.target.value;
                                setEmailerror("");
                                //document.getElementById("emailcheck").style.display = "none";
                              }}
                            />
                            <div className="tick-icon1">
                              <img
                                id="tick"
                                style={{ display: "none", width: "26px" }}
                                src={Tick}
                              />
                            </div>
                          </Form.Group>
                          <span
                            id="emailcheck"
                            style={{ display: "none", color: "red" }}
                          >
                            {" "}
                            Email is already registered
                          </span>
                          <span
                            id="sendcodemsg"
                            style={{ display: "none", color: "green" }}
                          >
                            {" "}
                            Verification Email sent successfuly
                          </span>
                          <Form.Group className="mb-3" controlId="E-mail">
                            <lable
                              style={
                                showform === true ? styles.show : styles.hide
                              }
                            >
                              Enter Verification Code
                            </lable>
                            <input
                              name="subject"
                              value={"Verification Code"}
                              hidden={true}
                            />
                            <Form.Control
                              id="verificationCode"
                              as={NumberFormat}
                              style={
                                showform === true ? styles.show : styles.hide
                              }
                              onChange={(e) => setcheckcode(e.target.value)}
                              value={checkcode}
                            />
                          </Form.Group>
                          <span
                            id="codeerr"
                            style={{ display: "none", color: "red" }}
                          >
                            {" "}
                            Your Verification Code is Incorrect
                          </span>
                          <span
                            id="codeerr1"
                            style={{ display: "none", color: "green" }}
                          >
                            {" "}
                            Your Verification Code is Correct
                          </span>
                          <h6 style={{ color: "red" }}>{emailerr}</h6>
                        </Col>
                        <Col
                          style={{
                            textAlign: "center",
                            marginBottom: "15px",
                            marginTop: "-5px",
                          }}
                          sm={4}
                        >
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <Button
                              style={{ width: "fit-content" }}
                              id="codebutton"
                              variant="success"
                              onClick={sendEmail}
                              className="verifybtn"
                            >
                              Verify
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <Row>
                            <lable>Password</lable>
                          </Row>
                          <Row>
                            <InputGroup
                              id="passw"
                              className=""
                              autocomplete="off"
                            >
                              <Form.Control
                                id="password"
                                style={passworderr != "" ? styles.err : null}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                }}
                                type={
                                  type === "password" ? "password" : "input"
                                }
                                onClick={(e) => {
                                  setPassworderror("");
                                }}
                                // onKeyUp={() => hideerr()}
                                // onBlur={() => showerr()}
                                className={
                                  type === "input" ? "" : "confirmpass"
                                }
                                placeholder="Password"
                                aria-label="Password"
                                onCopy={(e) => {
                                  // e.clipboardData.setData("")
                                  e.preventDefault();
                                  return false;
                                }}
                                aria-describedby="basic-addon2"
                                value={password}
                                autocomplete="off"
                              />
                              {type === "password" ? (
                                <div className="search-icon">
                                  <i
                                    onClick={changetype}
                                    className="fa fa-eye-slash"
                                  ></i>
                                </div>
                              ) : (
                                <div className="search-icon">
                                  <i
                                    onClick={changetypepass}
                                    className="fa fa-eye"
                                  ></i>
                                </div>
                              )}
                            </InputGroup>

                            <h6 style={{ color: "red" }}>{passworderr}</h6>
                          </Row>
                        </Col>{" "}
                        <span
                          id="passempty"
                          style={{ color: "red", display: "none" }}
                        >
                          * Password cannot be empty
                        </span>
                        <span
                          id="passlength"
                          style={{ color: "red", display: "none" }}
                        >
                          * Password must be more than 7 characters
                        </span>
                        <span
                          id="passspecial"
                          style={{ color: "red", display: "none" }}
                        >
                          * Atleast 1 special character recommended
                        </span>
                      </Row>

                      <Row>
                        <Col>
                          <Row>
                            <lable>Confirm Password</lable>
                          </Row>
                          <InputGroup className="mb-3" autocomplete="off">
                            <Form.Control
                              className={type1 === "input" ? "" : "confirmpass"}
                              id="confirmPassword"
                              name="new-password"
                              style={passcheck != "" ? styles.err : null}
                              onChange={(e) => {
                                // document.getElementById(
                                //   "passmatch"
                                // ).style.display = "none";
                                setConfirmPassword(e.target.value);
                              }}
                              type={type1 === "password" ? "password" : "input"}
                              onClick={(e) => {
                                setpasscheck("");
                              }}
                              placeholder="Password"
                              autoComplete="new-passord"
                              aria-label="confirm-password"
                              autoSave="new-password"
                              aria-describedby="basic-addon2"
                              value={confirmpassword}
                              autocomplete="off"
                            />

                            {type1 === "password" ? (
                              <div className="eye-icon">
                                <i
                                  onClick={changetype1}
                                  className="fa fa-eye-slash"
                                ></i>
                              </div>
                            ) : (
                              <div className="eye-icon">
                                <i
                                  onClick={changetypepass1}
                                  className="fa fa-eye"
                                ></i>
                              </div>
                            )}
                          </InputGroup>
                          <h6 style={{ color: "red" }}>{passcheck}</h6>
                          <span
                            id="passmatch"
                            style={{ color: "red", display: "none" }}
                          >
                            * Password Mismatch
                          </span>
                        </Col>
                        <div className="invalid-feedback"></div>
                      </Row>
                    </div>
                  </>
                ) : null}
                <Row className="mt-3">
                  {props.is1FX || props.isHomeFX ? null : (
                    <Col>
                      <Button
                        onClick={() => {
                          let temp = sessionStorage.getItem("previousPathName");
                          if (temp == "/OrderCom") {
                            navigate(`/OrderCom?ID=${uid}&t=${Date.now()}`);
                          } else {
                            navigate(`/OrderFXHome?ID=${uid}&t=${Date.now()}`);
                          }
                        }}
                        variant="danger"
                        className="btnlogin1"
                        style={{marginTop:"15px"}}
                      >
                        <h6 style={{ margin: 0, textTransform: "capitalize" }}>
                          Cancel
                        </h6>
                      </Button>
                    </Col>
                  )}
                  {docid.length > 0 ? (
                    <Col style={{ textAlign: "center" }}>
                      <Button
                        onClick={Updatedoc}
                        variant="success"
                        className="btnlogin1"
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Col>
                  ) : (
                    <>
                      {props.is1FX || props.isHomeFX ? null : (
                        <Col
                          style={{
                            justifyContent: "center",
                            textAlign: "center", // Center-align content horizontally
                          }}
                        >
                          <Button
                            onClick={() => {
                              PutonHold();
                            }}
                            variant="warning"
                            className="btnlogin1 "
                            style={{marginTop:"15px"}}
                          >
                            {isLoadingHold ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : (
                              <h6
                                style={{
                                  margin: 0,
                                  textTransform: "capitalize",
                                  textWrap: "nowrap",
                                }}
                              >
                                Put on Hold
                              </h6>
                            )}
                          </Button>
                        </Col>
                      )}
                      <Col
                        style={{
                          justifyContent: "center",
                          textAlign: "center", // Center-align content horizontally
                        }}
                      >
                        <Button
                          onClick={
                            props.is1FX ? ifFxSignup : () => Activate(uid)
                          }
                          variant="primary"
                          className={
                            props.is1FX || props.isHomeFX ? "" : "btnlogin1"
                          }
                          style={{marginTop:"15px"}}
                          ref={activateBtn}
                          disabled={false}
                        >
                          {isLoading ? (
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          ) : (
                            <h6
                              style={{
                                margin: 0,
                                textTransform: "capitalize",
                                textWrap: "nowrap",
                              }}
                            >
                              {props.is1FX ? "Submit" : "Activate Order"}
                            </h6>
                          )}
                        </Button>
                        <p
                          style={{
                            fontStyle: "italic",
                            color: "grey",
                            textAlign: "center", // Center-align content horizontally
                            marginTop: "5px", // Add some spacing between lines
                          }}
                        >
                          {props.is1FX ? (
                            <p>
                              By pressing Submit, I acknowledge having read and
                              agreeing to
                              <br />
                              the{" "}
                              <a href="/Terms" target="_blank">
                                Terms & Conditions
                              </a>{" "}
                              and{" "}
                              <a href="/PrivacyPolicy" target="_blank">
                                Privacy Policy
                              </a>
                              .
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container Rate-Date-Time">
        <div id="rate-date-time">
          The FX rates are as of:
          <span style={{ color: "#909090" }}>
            {" "}
            &nbsp;&nbsp; <i> {ddate} </i>
          </span>
          {/* &nbsp;&nbsp; Time : &nbsp; */}
          <span style={{ color: "#909090" }}>
            &nbsp;&nbsp; <i> {time} </i>
          </span>
        </div>
      </div>
    </div>
  );
}
export default CreateOrder;
