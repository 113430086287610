import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
// import { Row, Col, Modal, Button } from "react-bootstrap";
import notifsound from "../asstes/notif.wav";
import { apiHost, addDoc } from "../Helper";
import { AppContext } from "../Context";
import Picture from "../asstes/Profile.svg";
import { useNavigate } from "react-router-dom";
import Notify from "../asstes/notify.svg";
import NotifyWithDot from "../asstes/notifywithdot.svg";
import close from "../asstes/close.svg";
// import Stars from "../../asstes/rattingstar.svg";
import Stars from "../asstes/rattingstar.svg";
import {
  Row,
  Col,
  Modal,
  Tooltip,
  OverlayTrigger,
  NavDropdown,
  Button,
  Dropdown,
  Spinner,
} from "react-bootstrap";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const TopUpNotification = () => {
  const [notifData, setNotifData] = useState([]);
  const [visitedLength, setVisitedLength] = useState(0);
  const [uid, setUID] = useState(sessionStorage.getItem("UserID"));
  const [notification, setNotification] = useState({});
  const [showtoast, setShowtoast] = useState(false);
  const [cardShow, setCardShow] = useState(false);
  const [list, setlist] = useState("");
  const [loading, setloading] = useState(true);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [signupShow, setSignupShow] = useState(false);
  const [showMessageTab, setShowMessageTab] = useState(true);
  const [showInviteTab, setShowInvateTab] = useState(false);
  const [message,setMessage]=useState([])
  const inviteArray = []
  const messageArray = []

  const handleSignupClose = () => setSignupShow(false);

  const totalNotifications = notifData.length;
  const unreadNotifications = notifData.filter(
    (notif) => !notif.isVisited
  ).length;
  const notificationRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [starFill, setstarFill] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setIsMobile(mobile);
  }, []);

  // Function to play notification sound
  function soundFunc() {
    var x = document.getElementById("notificationSound");
    x.play();
  }

  // Function to set the notification body
  const toastBodySet = (obj) => {
    setNotification({
      title: obj.type.charAt(0).toUpperCase() + obj.type.slice(1),
      body: obj.description,
    });
    if (window.location.pathname === "/ChatPage" && obj.type === "Message") {
      setShowtoast(false);
    } else {
      setShowtoast(true);
    }
    soundFunc();
  };

  const getMessage = async () => {
    let count = 0;
    let notifications = [];
    let url = `${apiHost}/api/Orders/GetMessageNotifs?UserId=${uid}`; // Replace with the correct API endpoint
    axios
        .get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
        .then((response) => {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            response.data.messagesNotif.forEach((d, i) => {
                if (d.type === "message") {
                    let messageDate = new Date(d.time);
                    messageDate.setHours(0, 0, 0, 0); // Set the message date to midnight for accurate comparison

                    let newtime;
                    if (messageDate.getTime() === today.getTime()) {
                        // If the message is from today, show the time with AM/PM
                        newtime = new Date(d.time).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        });
                    } else {
                        // If the message is not from today, show only the date
                        newtime = d.time.split("T")[0];
                    }

                    if (new Date(d.time) > new Date().setDate(new Date().getDate() - 7)) {
                        if (!d.isVisited || d.isVisited === null) {
                            count++;
                        }

                        let obj = { newtime: newtime, ...d };
                        messageArray.push(obj);
                    }

                    setVisitedLength(count);
                    setMessage(messageArray);
                } else {
                    let inviteDate = new Date(d.time);
                    inviteDate.setHours(0, 0, 0, 0); // Set the invite date to midnight for accurate comparison

                    let newtime;
                    if (inviteDate.getTime() === today.getTime()) {
                        // If the invite is from today, show the time with AM/PM
                        newtime = new Date(d.time).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        });
                    } else {
                        // If the invite is not from today, show only the date
                        newtime = d.time.split("T")[0];
                    }

                    if (new Date(d.time) > new Date().setDate(new Date().getDate() - 7)) {
                        if (!d.isVisited || d.isVisited === null) {
                            count++;
                        }

                        let obj = { newtime: newtime, ...d };
                        inviteArray.push(obj);
                    }

                    setVisitedLength(count);
                    setNotifData(inviteArray);
                }
            });
        });
};



  useEffect(() => {
    setUID(sessionStorage.getItem("UserID"));
    const x = sessionStorage.getItem("logedin");
    if (x === "false") {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getMessage()
  }, [context.applicationStore.notificationMessage]);

  const Getdata = async () => {
    let url = `${apiHost}/api/User/GetUserById?UserId=${uid}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(async (response) => {
        const data = response.data;
        const image = response.data.profileimagepath;
        if (image == "" || image == null) {
          let name = "storageImage";
          data[name] = "";
          setlist(data);
          setloading(false);
        } else {
          let name = "storageImage";
          data[name] = `${apiHost}/Images/${image}`;
          setlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    Getdata();
  }, [uid, loading, context.applicationStore.navigation]);

  const moveToPage = (notifObj) => {
    let notifType = notifObj.type;
    if (!notifObj.isVisited) {
      addDoc("RemoveNotif", {
        description: notifObj.description,
      }).then(() => {
        // getNotifs();
        getMessage()
      });
    }
    context.dispatch({ Type: "NAVIGATION" });
    if (notifType == "acceptinvite") {
      let orderId = notifObj.description.split("order")[1].trim();
      navigate(`/ChatPage?ID=${uid}?OrderId=${orderId}&t=${Date.now()}`);
    } else if (notifType == "invite") {
      let orderId = notifObj.description.split("order")[1].trim();
      navigate(`/Match_Tab?ID=${uid}?OrderId=${orderId}&t=${Date.now()}`);
    } else if (notifType == "rejectinvite") {
      let orderId = notifObj.description.split("order")[1].trim();
      navigate(`/Match_Tab?ID=${uid}?OrderId=${orderId}&t=${Date.now()}`);
    }
  };

  const navigateSignup = () => {
    let obj = {
      email: sessionStorage.getItem("email"),
      userId: sessionStorage.getItem("UserID"),
      userName: sessionStorage.getItem("UserName"),
    };
    let jsonString = JSON.stringify(obj);
    let encodedString = encodeURIComponent(jsonString);
    navigate(`/SignUp?data=${encodedString}`);
    setSignupShow(false);
  };


  return (
    <>
      <Modal
        show={signupShow}
        onHide={handleSignupClose}
        size="md"
        className="oneFXSignUpModal"
      >
        <Modal.Header>
          <Modal.Title>Full FX </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "17px" }}>
            You need to signup as Full-FX user.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <Button
              variant="Secondary"
              onClick={handleSignupClose}
              style={{ backgroundColor: "#9FA6B2", color: "white" }}
            >
              Cancel
            </Button>
          </Col>
          <Col style={{ textAlign: "end" }}>
            <Button variant="primary" onClick={navigateSignup}>
              SignUp
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
      <div
        ref={notificationRef}
        className={`notificationTopup  cursor-pointer ${
          showNotification ? "show-notificationTopup" : ""
        }`}
        onClick={()=>{setShowNotification(true)}}
        // onMouseEnter={() => !isMobile && setShowNotification(true)}
        // onMouseLeave={() => !isMobile && setShowNotification(false)}
        // onClick={() => isMobile && setShowNotification(!showNotification)}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            padding: "5px 2px",
            backgroundColor: "#2995be",
            color: "white",
          }}
          onClick={()=>{setShowNotification(!showNotification)}}
        >
          <div className="d-flex justify-content-between align-items-center p-1">
            <img
              style={{
                backgroundColor: "#2995be",
                borderRadius: "50%",
                height: "25px",
                width: "25px",
                borderWidth: 3,
                marginLeft: "-2px",
                marginRight: "10px",
              }}
              src={unreadNotifications > 0 ? NotifyWithDot : Notify}
              alt="Notification Icon"
            />
            &nbsp;&nbsp;
            <h5
              style={{
                fontSize: 16,
                fontWeight: "500",
                marginBottom: "0px",
              }}
            >
              Notifications
            </h5>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p style={{ fontSize: 12, marginBottom: "0px" }}>(Last 7 days)</p>
          </div>
          <div
          >
            <img
              src={close}
              onClick={(e) => {
                e.stopPropagation();
                setShowNotification(false);
              }}
              className={`varrow showNotification ${
                showNotification ? "rotated" : ""
              }`}
              alt="logo"
            />
          </div>
        </div>

        {showNotification && (
          <>
            {/* **********SearchBox********** */}
            <div class="input-group">
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  // alignItems: "center",
                  width: "100%",
                }}
              >
                {/* left */}
                <div className="mt-3">
                  <button
                    id="search-button"
                    style={{
                      outline: "none",
                      border: "0px",
                      position: "absolute",
                      top: "19px",
                      left: "30px",
                      background: "transparent",
                    }}
                    type="button"
                  >
                    <i class="fas fa-search"></i>
                  </button>
                  <div class="form-outline w-100" data-mdb-input-init>
                    {showMessageTab === true ? (
                      <input
                        id="search-input"
                        type="search"
                        // id="form1"
                        placeholder="Search Messages"
                        class="form-control search_box pl-5"
                        style={{
                          width: "80%",
                          margin: "0 auto",
                          background: "#E7E7E7",
                          padding: "17px",
                        }}
                      />
                    ) : (
                      <input
                        id="search-input"
                        type="search"
                        // id="form1"
                        placeholder="Search Invites"
                        class="form-control search_box pl-5"
                        style={{
                          width: "80%",
                          margin: "0 auto",
                          background: "#E7E7E7",
                          padding: "17px",
                        }}
                      />
                    )}
                  </div>
                  {/* right */}
                </div>
                {/* <div>arrow down</div> */}
              </div>
              <div
                style={{
                  width: "100%",
                }}
                className="mt-3 "
              >
                <div className="w-100 d-flex">
                  <p
                    className={`${
                      showMessageTab === true
                        ? "activenotifTab"
                        : "nonActiveTabfont"
                    } text-center py-2`}
                    style={{ fontWeight: "bold", flex: "1 1 0" }}
                    onClick={() => {
                      setShowMessageTab(true);
                      setShowInvateTab(false);
                    }}
                  >
                    Messages
                  </p>
                  <p
                    className={`${
                      showInviteTab === true
                        ? "activenotifTab"
                        : "nonActiveTabfont"
                    } text-center py-2`}
                    style={{ fontWeight: "bold", flex: "1 1 0" }}
                    onClick={() => {
                      setShowMessageTab(false);
                      setShowInvateTab(true);
                    }}
                  >
                    Invites
                  </p>
                </div>
                <div>
                  {showMessageTab && (
                  <div className="">
                      <div
                        className=""
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                        onClick={() => {
                          if (cardShow) {
                            setCardShow(false);
                          } else {
                            setCardShow(true);
                          }
                        }}
                      >
                        <div className="card-body p-0">
                          {/* <div></div> */}
                          {message.map((item, index) => (
                            <div
                              key={index}
                              onClick={(k) => {
                                if (sessionStorage.getItem("City") == "null") {
                                  setSignupShow(true);
                                } else {
                                  moveToPage(item);
                                }
                              }}
                              className={
                                item.isVisited
                                  ? "notifItem-visited"
                                  : "notifItem-notvisited"
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "smaller",
                                backgroundColor:
                                  index % 2 === 0 ? "#F5F5F5" : "#ffffff",
                                  border:"0px",
                                // borderBottom: index % 2 === 0 && "#D5D5D5",
                                fontWeight: item.isVisited ? "normal" : "bold",
                                padding: "4px",
                                marginBottom: "2px",
                                paddingBottom:"25px"
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  flex:"1",
                                }}
                                className="pr-1"
                              >
                                <img
                                  src={
                                    item.senderProfileimagepath == ""
                                      ? Picture
                                      : `${apiHost}/Images/${item.profileimagepath}`
                                  }
                                  alt="User Profile"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    // marginRight: "10px",
                                    border: "1px solid #d5d5d5",
                                  }}
                                />
                                {/* <Row
                                  className="mt-0"
                                  style={{ textAlign: "center" }}
                                >
                                  <Col className="d-flex">
                                    <img
                                      className={`
                                ${
                                  starFill >= 1
                                    ? "modal-rating-fill"
                                    : "modal-rating-unfill"
                                }
                                  
                              `}
                                      src={Stars}
                                      onClick={() => setstarFill(1)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 2
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(2)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 3
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(3)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 4
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(4)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 5
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(5)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                  </Col>
                                </Row> */}
                                <Row style={{ position: "relative" }}>
                          <OverlayTrigger
                            overlay={
                              <Tooltip
                                id="tooltip-disabled"
                                bsPrefix="custom-tooltip"
                                style={{ position: "absolute", zIndex: 999 }}
                              >
                                {item.reviewScore} reviews
                              </Tooltip>
                            }
                            placement="top-start"
                          >
                            <Col md={12}>
                              <img
                                // id={`${list.ID}-star-1`}
                                // onClick={(e) => rating(e, 1)}
                                className={
                                  item.reviewScore >= 1
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-2`}
                                // onClick={(e) => rating(e, 2)}
                                className={
                                  item.reviewScore >= 2
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-3`}
                                // onClick={(e) => rating(e, 3)}
                                className={
                                  item.reviewScore >= 3
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-4`}
                                // onClick={(e) => rating(e, 4)}
                                className={
                                  item.reviewScore >= 4
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-5`}
                                // onClick={(e) => rating(e, 5)}
                                className={
                                  item.reviewScore >= 5
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                            </Col>
                          </OverlayTrigger>
                        </Row>
                              </div>
                              <div style={{flex:"2"}}> 
                                <div className="d-flex justify-content-between">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {" "}
                                    {/* {item.description.split(" ")[0]} */}
                                    {item.senderName}
                                  </p>

                                  {/* <p
                                    className="datenotify pr-2 mb-0"
                                    style={{
                                      fontWeight: "bold",
                                      marginTop: "2px",
                                      color: "black",
                                    }}
                                  >
                                    {" "}
                                    {item.newtime.split(" at ")[1]}
                                  </p> */}
                                </div>
                                <div
                                  className="subjectnotify"
                                  style={{ color: "black" }}
                                >
                                  {item.description}
                                </div>
                                <div
                                  className="datenotify"
                                  style={{ color: "black" }}
                                >
                                  {item.newtime}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {showInviteTab && (
                    <div className="">
                      <div
                        className=""
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                        onClick={() => {
                          if (cardShow) {
                            setCardShow(false);
                          } else {
                            setCardShow(true);
                          }
                        }}
                      >
                        <div className="card-body p-0">
                          {/* <div></div> */}
                          {notifData.map((item, index) => (
                            <div
                              key={index}
                              onClick={(k) => {
                                if (sessionStorage.getItem("City") == "null") {
                                  setSignupShow(true);
                                } else {
                                  moveToPage(item);
                                }
                              }}
                              className={
                                item.isVisited
                                  ? "notifItem-visited"
                                  : "notifItem-notvisited"
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "smaller",
                                backgroundColor:
                                  index % 2 === 0 ? "#F5F5F5" : "#ffffff",
                                  border:"0px",
                                // borderBottom: index % 2 === 0 && "#D5D5D5",
                                fontWeight: item.isVisited ? "normal" : "bold",
                                padding: "4px",
                                marginBottom: "2px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  flex:"1"
                                }}
                                className="pr-1"
                              >
                                <img
                                  src={
                                    item.senderProfileimagepath == ""
                                      ? Picture
                                      : `${apiHost}/Images/${item.profileimagepath}`
                                  }
                                  alt="User Profile"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    // marginRight: "10px",
                                    border: "1px solid #d5d5d5",
                                  }}
                                />
                                {/* <Row
                                  className="mt-0"
                                  style={{ textAlign: "center" }}
                                >
                                  <Col className="d-flex">
                                    <img
                                      className={`
                                ${
                                  starFill >= 1
                                    ? "modal-rating-fill"
                                    : "modal-rating-unfill"
                                }
                                  
                              `}
                                      src={Stars}
                                      onClick={() => setstarFill(1)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 2
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(2)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 3
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(3)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 4
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(4)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                    <img
                                      className={
                                        starFill >= 5
                                          ? "modal-rating-fill"
                                          : "modal-rating-unfill"
                                      }
                                      src={Stars}
                                      onClick={() => setstarFill(5)}
                                      alt="Stars"
                                      style={{ width: "6px" }}
                                    />
                                  </Col>
                                </Row> */}
                                <Row style={{ position: "relative" }}>
                          <OverlayTrigger
                            overlay={
                              <Tooltip
                                id="tooltip-disabled"
                                bsPrefix="custom-tooltip"
                                style={{ position: "absolute", zIndex: 999 }}
                              >
                                {item.reviewScore} reviews
                              </Tooltip>
                            }
                            placement="top-start"
                          >
                            <Col md={12}>
                              <img
                                // id={`${list.ID}-star-1`}
                                // onClick={(e) => rating(e, 1)}
                                className={
                                  item.reviewScore >= 1
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-2`}
                                // onClick={(e) => rating(e, 2)}
                                className={
                                  item.reviewScore >= 2
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-3`}
                                // onClick={(e) => rating(e, 3)}
                                className={
                                  item.reviewScore >= 3
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-4`}
                                // onClick={(e) => rating(e, 4)}
                                className={
                                  item.reviewScore >= 4
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                              <img
                                // id={`${list.ID}-star-5`}
                                // onClick={(e) => rating(e, 5)}
                                className={
                                  item.reviewScore >= 5
                                    ? "rating-fill"
                                    : "rating-unfill"
                                }
                                src={Stars}
                                alt="Stars"
                              />
                            </Col>
                          </OverlayTrigger>
                        </Row>
                              </div>
                              <div style={{flex:"3"}}> 
                                <div className="d-flex justify-content-between">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {" "}
                                    {/* {item.description.split(" ")[0]} */}
                                    {item.senderName}
                                  </p>

                                  {/* <p
                                    className="datenotify pr-2 mb-0"
                                    style={{
                                      fontWeight: "bold",
                                      marginTop: "2px",
                                      color: "black",
                                    }}
                                  >
                                    {" "}
                                    {item.newtime.split(" at ")[1]}
                                  </p> */}
                                </div>
                                <div
                                  className="subjectnotify"
                                  style={{ color: "black" }}
                                >
                                  {item.description}
                                </div>
                                <div
                                  className="datenotify"
                                  style={{ color: "black" }}
                                >
                                  {item.newtime}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div
              className=""
              style={{ maxHeight: "400px", overflowY: "auto" }}
              onClick={() => {
                if (cardShow) {
                  setCardShow(false);
                } else {
                  setCardShow(true);
                }
              }}
            >
              <div className="card-body">
                <div></div>
                {notifData.map((item, index) => (
                  <div
                    key={index}
                    onClick={(k) => {
                      if (sessionStorage.getItem("City") == "null") {
                        setSignupShow(true);
                      } else {
                        moveToPage(item);
                      }
                    }}
                    className={
                      item.isVisited
                        ? "notifItem-visited"
                        : "notifItem-notvisited"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "smaller",
                      backgroundColor: index % 2 === 0 ? "#dbdbdb" : "#ffffff",
                      fontWeight: item.isVisited ? "normal" : "bold",
                      padding: "4px",
                      marginBottom: "2px",
                    }}
                  >
                    <img
                      src={
                        item.senderProfileimagepath == ""
                          ? Picture
                          : `${apiHost}/Images/${item.senderProfileimagepath}`
                      }
                      alt="User Profile"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "10px",
                        border: "1px solid",
                      }}
                    />
                    <div>
                      <div className="subjectnotify" style={{ color: "black" }}>
                        {item.description}
                      </div>
                      <div className="datenotify" style={{ color: "black" }}>
                        {item.newtime}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default TopUpNotification;
