//Navbar.js
import React, { useContext, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import mute from "../../asstes/mute.png";
import unmute from "../../asstes/unmute.png";
import Picture from "../../asstes/Profile.svg";
import { AppContext } from "../../Context";
import Map from "../../asstes/map.png";
import { apiHost } from "../../Helper";

export const NavbarDropDown = ({
  onClick,
  userData,
  isMute,
  headerState,
  signout,
  soundActivate,
}) => {
  const [currentPage, setCurrentPage] = useState("");
  const context = useContext(AppContext);
  const randomFunction = () => {
    context.dispatch({ Type: "NAVIGATION" });
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  return (
    <NavDropdown
      className={`show1 ${
        headerState == "orderfx" ? "header-dropdown-orderfx" : ""
      }`}
      id="collasible-nav-dropdown mobileNameDrop"
      style={{ color: "#fff !important" }}
      title={
        <div
          className={`${
            currentPage == "/OrderFXHome"
              ? "header-dropdown-orderFxHome"
              : "header-dropdown"
          } notranslate`}
        >
          <img
            id={"ProfilePic"}
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "37px",
              width: "37px",
              borderWidth: 3,
              borderColor: "#fff",
              marginRight: "4px",
            }}
            src={sessionStorage?.getItem("settingProfilePic")?.split(",")[0] !== null &&
              userData.userName === sessionStorage?.getItem("settingProfilePic")?.split(",")[1]
              ? `${apiHost}/Images/${sessionStorage?.getItem("settingProfilePic")?.split(",")[0]}` : ""}
          />
          {userData.userName}
        </div>
      }
    >
      {headerState == "orderfx" ? (
        <>
          <NavDropdown.Item
            style={{ color: "black" }}
            onClick={() => soundActivate(!isMute)}
          >
            Sound &nbsp;&nbsp;&nbsp;&nbsp;
            <img id="sound" src={isMute ? mute : unmute} width="12px" />
          </NavDropdown.Item>
          <hr style={{ width: "80%", margin: "0px 0px 0px 10px" }} />
          <NavDropdown.Item style={{ color: "black" }} onClick={signout}>
            Logout
          </NavDropdown.Item>
        </>
      ) : (
        <>
          <NavDropdown.Item onClick={onClick}>
            <Link
              onClick={() => {
                context.dispatch({
                  Type: "NavMobile",
                  Payload: { tab: "Profile" },
                });
                randomFunction();
              }}
              style={{ color: "black", textDecoration: "none" }}
              to={`/editprofile?ID=${sessionStorage.UserID}`}
            >
              Edit Profile
            </Link>
          </NavDropdown.Item>
          <hr style={{ width: "80%", margin: "0px 0px 0px 10px" }} />
          <NavDropdown.Item onClick={onClick}>
            <Link
              onClick={randomFunction}
              style={{ color: "black", textDecoration: "none" }}
              to={`/OrderFXHome?ID=${sessionStorage.UserID}`}
            >
              1-Order-FX
            </Link>
          </NavDropdown.Item>
          <hr style={{ width: "80%", margin: "0px 0px 0px 10px" }} />
          <NavDropdown.Item
            style={{ color: "black" }}
            onClick={() => soundActivate(!isMute)}
          >
            Sound &nbsp;&nbsp;&nbsp;&nbsp;
            <img id="sound" src={isMute ? mute : unmute} width="12px" />
          </NavDropdown.Item>
          <hr style={{ width: "80%", margin: "0px 0px 0px 10px" }} />
          <NavDropdown.Item style={{ color: "black" }} onClick={signout}>
            Logout
          </NavDropdown.Item>
        </>
      )}
    </NavDropdown>
  );
};

export const NavComponent = ({
  onClick,
  navClass,
  linkClassName,
  userData,
  isMute,
  headerState,
  signout,
  soundActivate,
}) => {
  const context = useContext(AppContext);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");

  useEffect(() => {
    console.log(context.applicationStore.tab);
  }, [context]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, [null]);

  return (
    <>
      <nav className={navClass}>
        {[
          { name: "My Orders", link: "/OrderCom" },
          { name: "Matches", link: "/Match_Tab" },
          { name: "Finalize", link: "/ChatPage" },
          { name: "Watchlist", link: "/watchlist" },
          { name: "MC", link: "/MC" },
          { name: "Tips", link: "/Tips" },
          { name: "MC Prime", link: "/McPrime" },
        ].map((section) => (
          <Link
            to={`${section.link}?ID=${sessionStorage.getItem("UserID")}`}
            key={section.name}
            smooth={true}
            className={linkClassName}
            style={
              context.applicationStore.tab === section.name
                ? {
                    border: "1px solid",
                    padding: "7.5px 15px",
                    borderRadius: "5%",
                    minWidth: "50%;",
                    // width: "80%",
                    // paddingLeft: "5px"
                  }
                : {
                    padding: "7.5px 15px",
                  }
            }
            onClick={() => {
              onClick();
              context.dispatch({
                Type: "NavMobile",
                Payload: { tab: section.name },
              });
            }}
          >
            {section.name}
          </Link>
        ))}{" "}
        <a className="nav-small-link" style={{ padding: "7.5px 15px" }}>
          <img
            src={Map}
            style={{ width: 20, height: 20 }}
            onClick={() => {
              //  navigate('/Map')
              window.open(
                `https://www.google.com/maps/search/money+changers+near+me/@${latitude},${longitude},15z/data=!3m1!4b1', '_blank', 'noreferrer'`
              );
              // document.getElementById("navbarScroll").className =
              //   "navbar-collapse collapsing";
              // setTimeout(() => {
              //   document.getElementById("toggler").classList.add("collapsed");
              //   document.getElementById("navbarScroll").className =
              //     "navbar-collapse collapse";
              // }, 350);
            }}
          />
        </a>
      </nav>
      <NavbarDropDown
        userData={userData}
        isMute={isMute}
        headerState={headerState}
        signout={signout}
        soundActivate={soundActivate}
        onClick={onClick}
      />
    </>
  );
};
// export default Navbar;
